import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import Main from '../components/apply/main'
import Header from '../components/header'

const ApplyPage = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'apply',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <Layout phone="866-740-2901" location={location}>
        <SEO
          title="Home"
          keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
        />
        <Header phone="866-740-2901" isR4M={false} TTYBig={false} />
        <Intro showTitle={false} colType="full-col">
          <Main location={location} startStep="birth" />
        </Intro>
        <main id="main">
          <Partners />
        </main>
      </Layout>
    </div>
  )
}

export default ApplyPage
